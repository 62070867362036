<template>
  <div id="home">
    <div class="top">
      <div class="sidebar">
        <el-scrollbar style="height: 100%; width: 200px" ref="sub">
          <ul class="menu">
            <li
              v-for="(navList, index) in Classification"
              :key="index"
              class="forRealitive"
              @mouseover="showToggle(index)"
              @mouseout="handleHide"
            >
              <p class="sidebar-link" @click="goCategory(index)">
                <img v-lazy="navList.categoryImage" /><a>{{
                  navList.categoryName
                }}</a>
                <svg-icon icon-class="ic-arrow-b" class="ic-arrow" />
              </p>
              <ul class="menu-sub" v-show="index === isShow">
                <li v-for="(item, idx) in navList.subItemList" :key="idx">
                  <p
                    class="menu-title"
                    :title="item.categoryName"
                    @click="goCategory(index, idx)"
                  >
                    {{ item.categoryName }}<span>&gt;</span>
                  </p>

                  <div class="three-menu">
                    <a
                      class="section-link"
                      @click="goCategory(index, idx, i)"
                      v-for="(list, i) in item.subItemList"
                      :key="i"
                      :title="list.categoryName"
                      >{{ list.categoryName }}
                    </a>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </el-scrollbar>
      </div>
      <div class="banner">
        <el-carousel :interval="6000" arrow="always" height="360px">
          <el-carousel-item v-for="(list, i) in Banner" :key="i">
            <img
              v-lazy="list.picPosition"
              alt="轮播图"
              @click="skip(list)"
              :style="list.urlType ? 'cursor: pointer;' : ''"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="user">
        <div class="user-box">
          <img
            :src="
              userInfo.userDetailEntity.profilePicture === null
                ? require('@/assets/images/user.png')
                : userInfo.userDetailEntity.profilePicture
            "
            alt="用户头像"
          />
          <p v-if="userLogin">Hi，{{ userInfo.userEntity.telNum }}</p>
          <p v-else>Hi，{{ userData.userEntity.name || "您好" }}</p>

          <div v-if="userLogin">
            <!-- <a href="/#/PersonalCenter">个人中心</a> -->
            <a @click="PersonalCenter" style="cursor: pointer">个人中心</a>
            <a @click="logOut" style="cursor: pointer">退出登录</a>
          </div>

          <div v-else-if="isId && isLogin">
            <a style="width: 120px" href="https://admin.origmall.cn"
              >请完善公司资料</a
            >
          </div>
          <div v-else-if="isLogin">
            <a style="width: 100px" href="https://admin.origmall.cn"
              >前往管理中心</a
            >
          </div>
          <div v-else>
            <a href="#/Login">登录</a>
            <!-- <a href="#/Login/Register">注册</a> -->
          </div>
        </div>
        <div class="news">
          <div class="title">
            <p>新闻资讯</p>
            <router-link to="/News">More>></router-link>
          </div>
          <div class="content">
            <ul v-if="!none">
              <li v-for="(list, index) in News" :key="index">
                <i></i
                ><span
                  @click="goNewsDetails(list.newsCode)"
                  :title="list.newsTitle"
                  >{{ list.newsTitle }}</span
                >
              </li>
            </ul>
            <!-- <div>
              <img src="@/assets/images/none.png" alt="">
              <p>暂时没有新闻资讯哦~</p>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="activity">
      <div class="left">
        <p>活动专栏</p>
        <p>限时优惠</p>
        <router-link to="/AllActivities">查看更多>></router-link>
      </div>
      <div class="right">
        <div
          v-for="(list, index) in Activity"
          :key="index"
          @click="goActivitiesColumn(list.id)"
        >
          <img :src="ActivityPic[index].pic[0]" :alt="list.activityName" />
          <!-- <img src="" alt="" />
          <p></p> -->
        </div>
      </div>
    </div>
    <div class="recommended-brand">
      <div class="title">
        <div class="left">
          <img src="@/assets/images/RecommendedBrand.png" alt=" 推荐品牌" />
          <h2>推荐品牌</h2>
        </div>
        <div class="right">
          <router-link to="/Brand/AllBrand"
            >全部品牌<svg-icon icon-class="ic-arrow-d"
          /></router-link>
        </div>
      </div>
      <div class="content">
        <div
          class="brand"
          v-for="(list, index) in RecommendedBrand"
          :key="index"
          @click="goBrand(list.id, list.brandId, list.brandName)"
        >
          <img
            :src="list.logo"
            :alt="list.brandName"
            style="object-fit: contain"
          />
          <p>{{ list.brandName }}</p>
        </div>
        <el-empty
          description="暂无品牌"
          v-show="RecommendedBrand.length === 0"
        ></el-empty>
      </div>
    </div>
    <recommended :goods="OrdinaryGoods" />
    <div class="recommend-store">
      <div class="title">
        <div class="left">
          <img src="@/assets/images/recommend-store.png" alt=" 推荐店铺" />
          <h2>推荐店铺</h2>
        </div>
        <div class="right">
          <router-link to="StoreList"
            >全部店铺<svg-icon icon-class="ic-arrow-d"
          /></router-link>
        </div>
      </div>
      <div class="content">
        <div
          class="store"
          v-for="(list, index) in StoreLIst"
          :key="index"
          @click="goStore(list.storeId, list.enterpriseId)"
        >
          <img v-lazy="list.storeLogo" :key="index" />
          <div>
            <h3>{{ list.storeName }}</h3>
            <p :title="list.storeIntroduce">{{ list.storeIntroduce }}</p>
          </div>
        </div>
        <el-empty
          description="暂无店铺"
          v-if="StoreLIst.length === 0"
        ></el-empty>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <p><svg-icon icon-class="ic_Tips" /> 请扫二维码下载正品汇店长端app。</p>
      <img src="@/assets/images/QR-code.png" alt="" />
    </el-dialog>
    <el-dialog title="" :visible.sync="ggShow" width="30%">

       <img
        width="100%"
        style="margin-top:20px;cursor: pointer;"
        src=" https://sgmall.origmall.cn/img/gg2.8dfa9210.jpg"
        alt=""
        @click="$router.push('/announcement')"
      />
    </el-dialog>
  </div>
</template>

<script>
import Recommended from "@/components/Recommended";
import { mapState } from "vuex";
import Cookies from "js-cookie";

export default {
  name: "home",
  components: {
    Recommended,
  },
  computed: {
    ...mapState("user", ["isLogin", "userData", "id"]),
    ...mapState("personalUser", ["userLogin", "userInfo"]),
  },
  watch: {
    id: {
      //监听store/user 里的isLogin有没改变
      handler(newValue) {
        if (newValue) {
          this.isId = false;
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      isShow: 1,
      Classification: "", //分类信息
      OrdinaryGoods: "", //推荐商品信息
      ItemCode: "", //推荐商品单品编码
      StoreLIst: "", //推荐店铺信息
      RecommendedBrand: "", //推荐品牌信息
      Banner: "", //轮播图
      Activity: "", //活动
      News: "", //新闻
      ActivityPic: "", //活动图片
      Id: "",
      isId: true,
      none: false,
      dialogVisible: false,
      ggShow: false,
    };
  },
  created() {
    this.getCommList();
    this.getQueryBrandList();
    this.getStoreLIst();
    this.getClassification();
    this.getBanner();
    this.getActivityList();
    this.getNews();
  },
  mounted() {},
  methods: {
    //首页推荐普通商品
    async getCommList() {
      const data = await this.$API.homePage.CommList({
        object: {
          brandId: "",
          ceCategoryId: null,
          location: "4",
          order: "desc",
          sortColumn: "",
        },
        pageNum: 1,
        pageSize: 100,
      });
      this.OrdinaryGoods = data.data.data.map((a) => {
        return {
          ...a,
          filePath: a.filePath.split(",")[0].endsWith(".mp4")
            ? a.filePath.split(",")[1]
            : a.filePath.split(",")[0],
        };
      });
      // console.log(data.data.data, "推荐商品");
    },
    //首页推荐品牌
    async getQueryBrandList() {
      const data = await this.$API.homePage.QueryBrandList({
        object: {
          location: "2",
        },
        pageNum: 1,
        pageSize: 100,
        // pageSize: 24,
      });
      this.RecommendedBrand = data.data.data;
      // console.log(data.data.data, "推荐品牌");
    },
    //获取首页店铺
    async getStoreLIst() {
      const data = await this.$API.homePage.storeLIst({
        object: {
          enterpriseId: "",
          location: "5",
        },
        pageNum: 1,
        pageSize: 10,
      });
      this.StoreLIst = data.data.data;
      // console.log(data.data.data, "推荐店铺");
    },
    //获取分类
    async getClassification() {
      // let category = localStorage.getItem("category");
      // if (category) {
      //   this.Classification = JSON.parse(category);
      // } else {
      const data = await this.$API.class.Classification({
        object: {
          enterpriseId: "",
        },
      });
      this.Classification = data.data.data.categoriesCatalog;
      localStorage.setItem("category", JSON.stringify(this.Classification));
      // }
    },
    //获取新闻列表
    async getNews() {
      const data = await this.$API.class.News({
        object: {
          type: "XWZX",
        },
        pageNum: 1,
        pageSize: 6,
      });
      this.News = data.data.data;
      // if (this.News === []) {
      //   this.none = true;
      // }
    },
    //获取轮播图
    async getBanner() {
      const data = await this.$API.homePage.getBanner({
        object: {
          location: "1",
        },
        pageNum: 0,
        pageSize: 0,
      });
      this.Banner = data.data.data;

      // console.log(data.data.data[0].picPosition);
      // console.log(data.data.data, "推荐店铺");
    },
    //获取活动
    async getActivityList() {
      const data = await this.$API.homePage.getActivityList({
        object: {
          location: "1",
        },
        pageNum: 0,
        pageSize: 4,
      });
      this.Activity = data.data.data;
      this.ActivityPic = data.data.data.map((item) => {
        return {
          pic: item.platformPic.split(","),
        };
      });
    },

    // async getEnterpriseId() {
    //   const data = await this.$API.user.getEnterpriseId({
    //     creatorIdentityId: "",
    //     creatorUserId: "",
    //   });
    //   this.Id = data.data.data.id;
    // },
    // async getCurrUser() {
    //   const data = await this.$API.user.getCurrUser({
    //     object: {},
    //   });
    //   // console.log(data.data.data);
    //   this.User = data.data.data.userInfo;
    //   console.log(this.User);
    // },
    //轮播图跳转
    skip(val) {
      console.log(val);
      if (
        val.picPosition ===
        "http://oa.realbrand.net/0619ead5b8d849ed847779a1b92e0e6d.jpg"
      ) {
        this.$router.push("/announcement");
        return;
      }

      switch (val.urlType) {
        case 1:
          this.$router.push({
            path: `Store/HomePage/${val.storeId}/GoodsDetails`,
            query: {
              commId: val.foreignKey,
            },
          });
          break;
        case 2:
          this.$router.push(`Store/HomePage/${val.storeId}`);
          break;
        case 3:
          console.log(val.foreignKey);
          break;

        default:
          break;
      }
    },
    //跳转店铺首页
    goStore(id, enterpriseId) {
      this.$router.push({
        path: `Store/HomePage/${id}`,
        query: { enterpriseId: enterpriseId },
      });
    },
    //跳转推荐品牌
    goBrand(id, brandId, brandName) {
      this.$router.push({
        path: `Brand/RecommendedBrand`,
        query: {
          id: id,
          brandId: brandId,
        },
      });
    },
    //跳转活动
    goActivitiesColumn(id) {
      this.$router.push({ path: `ActivitiesColumn/${id}` });
    },
    //跳转分类
    goCategory(index, idx, i) {
      let breadcrumb = [];
      breadcrumb.push(index);
      if (idx != undefined) breadcrumb.push(idx);
      if (i != undefined) breadcrumb.push(i);
      this.$router.push({
        name: "category-list",
        query: {
          breadcrumb: JSON.stringify(breadcrumb),
        },
      });
    },
    //跳转新闻
    goNewsDetails(newsCode) {
      this.$router.push({
        name: "news-details",
        params: {
          newsCode: newsCode,
        },
      });
    },
    showToggle(index) {
      if (this.$refs.sub.$children.length <= 12) {
        document.getElementsByClassName("is-vertical")[0].style.display =
          "none";
      }
      this.$refs.sub.$el.style.width = "960px";
      this.$refs.sub.$el.style.boxShadow = "6px 0px 8px rgba(0, 0, 0, 0.18)";
      this.isShow = index;
    },
    handleHide() {
      this.isShow = !this.isShow;
      this.$refs.sub.$el.style.width = "200px";
      this.$refs.sub.$el.style.boxShadow = "none";
    },
    PersonalCenter() {
      this.dialogVisible = true;
    },
    //退出登录
    logOut() {
      Cookies.remove("token_personal");
      sessionStorage.removeItem("userId");
      sessionStorage.removeItem("userType");
      location.reload();
      // window.location.replace(this.$URL);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/home.scss";
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

</style>
<style lang="scss">
#recommended .content .goods-box:nth-child(5n) {
  border-right: none;
}
</style>
